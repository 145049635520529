const getBaseUrl = () => '/embedded-viral-link';

export const getRootUrl = () => `${getBaseUrl()}/`;
export const getQueryParam = key => new URL(window.location.href).searchParams.get(key);

const getQueryParams = paramKeyNames => {
  return paramKeyNames.reduce((acc, currentKey) => {
    if (getQueryParam(currentKey)) {
      acc[currentKey] = getQueryParam(currentKey);
    }

    return acc;
  }, {});
};

export const getLiveChatParams = () => {
  const queryParams = new URL(window.location.href).searchParams;
  const intent = queryParams.get('intent');
  const viralSourcePortalId = queryParams.get('viralSourcePortalId');
  const hubs_medium = queryParams.get('hubs_medium');
  const hubs_campaign = queryParams.get('hubs_campaign');
  return Object.assign({}, intent && {
    intent
  }, {}, viralSourcePortalId && {
    viralSourcePortalId
  }, {}, hubs_medium && {
    hubs_medium
  }, {}, hubs_campaign && {
    hubs_campaign
  });
};
export const getFormsQueryParams = () => getQueryParams(['intent', 'hubs_id', 'utm_id', 'hubs_source', 'utm_source']);
export const getPortalIdFromUrl = () => {
  const portalId = getQueryParam('portalId');
  return portalId ? parseInt(portalId, 10) : null;
};