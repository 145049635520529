import enviro from 'enviro';
import { Locale } from 'signup-ui-lego-core/constants/Locale';
import { getPreferredReferrer } from './referrerUtils';
import { getLocaleFromUrl } from './urlUtils';
/**
 * Get the navigator languages.
 *
 * This method cover the case where a browser doesn't have the navigator.languages
 * available by returning an array with the current navigator.language.
 */

export const getNavigatorLanguages = () => {
  return navigator.languages ? [...navigator.languages] : [navigator.language];
};
export const getLanguages = (navigatorLanguages = getNavigatorLanguages(), url = getPreferredReferrer()) => {
  const locale = getLocaleFromUrl(url);

  if (locale && locale !== Locale.en && navigatorLanguages.length > 0 && navigatorLanguages[0].toLowerCase().indexOf(locale) === -1) {
    return [locale].concat(navigatorLanguages);
  }

  return navigatorLanguages;
};

const checkLangCode = value => {
  return Object.values(Locale).includes(value);
};

export const getPreferredLocale = (languages = getNavigatorLanguages(), referrer = getPreferredReferrer(), fallbackLocale = Locale.en) => {
  const url = new URL(document.location.href);
  const isQA = enviro.isQa();
  let locale;

  if (url.searchParams.has('lang')) {
    const queryLang = url.searchParams.get('lang');
    const isLangLocaleSupported = checkLangCode(queryLang);

    if (isLangLocaleSupported) {
      locale = queryLang;
    } else {
      const lang = queryLang.split('-')[0];
      const isLangSupported = checkLangCode(lang);

      if (isLangSupported) {
        locale = lang;
      }
    }

    if (isQA) {
      locale = queryLang;
    }
  } else {
    languages = getLanguages(languages, referrer);

    if (languages && languages.length > 0) {
      const browserLang = languages[0].toLowerCase();
      const isLangLocaleSupported = checkLangCode(browserLang);

      if (isLangLocaleSupported) {
        locale = browserLang;
      } else {
        const lang = browserLang.split('-')[0];
        const isLangSupported = checkLangCode(lang);

        if (isLangSupported) {
          locale = lang;
        }
      }
    }
  }

  return locale || fallbackLocale;
};