// The objects in this file map each text token prop value (e.g. `fontSize` = `bodyDefault`) to the correct theme token name (e.g. `HDS_TYPE_SCALE_BODY_MEDIUM`).
export const fontSizeMap = {
  bodySmall: 'HDS_TYPE_SCALE_BODY_SMALL',
  bodyDefault: 'HDS_TYPE_SCALE_BODY_DEFAULT',
  bodyLarge: 'HDS_TYPE_SCALE_BODY_LARGE',
  pageHeading: 'HDS_TYPE_SCALE_DISPLAY_HEADING1',
  taglineHeading: 'HDS_TYPE_SCALE_DISPLAY_HEADING1',
  heading1: 'HDS_TYPE_SCALE_DISPLAY_HEADING1',
  heading2: 'HDS_TYPE_SCALE_DISPLAY_HEADING2',
  heading3: 'HDS_TYPE_SCALE_DISPLAY_HEADING3',
  heading4: 'HDS_TYPE_SCALE_DISPLAY_HEADING4',
  heading5: 'HDS_TYPE_SCALE_DISPLAY_HEADING5',
  heading6: 'HDS_TYPE_SCALE_DISPLAY_HEADING6'
};
export const fontWeightMap = {
  bodyDefault: 'HDS_FONT_WEIGHT_BODY_DEFAULT',
  bodyBold: 'HDS_FONT_WEIGHT_BODY_BOLD',
  displayLight: 'HDS_FONT_WEIGHT_DISPLAY_LIGHT',
  displayMedium: 'HDS_FONT_WEIGHT_DISPLAY_MEDIUM',
  displayDefault: 'HDS_FONT_WEIGHT_DISPLAY_DEFAULT',
  displayBold: 'HDS_FONT_WEIGHT_DISPLAY_BOLD',
  codeDefault: 'HDS_FONT_WEIGHT_CODE_DEFAULT'
};
export const fontFamilyMap = {
  default: 'HDS_FONT_FAMILY_DEFAULT',
  code: 'HDS_FONT_FAMILY_CODE'
};
export const lineHeightMap = {
  bodyTight: 'HDS_LINE_HEIGHT_BODY_TIGHT',
  bodyDefault: 'HDS_LINE_HEIGHT_BODY_DEFAULT',
  display: 'HDS_LINE_HEIGHT_DISPLAY',
  code: 'HDS_LINE_HEIGHT_CODE'
};
export const textVariantMap = {
  body: 'HDS_TEXT_BODY',
  bodySmall: 'HDS_TEXT_BODY_SMALL',
  bodyLarge: 'HDS_TEXT_BODY_LARGE',
  code: 'HDS_TEXT_CODE',
  heading1: 'HDS_DISPLAY_HEADING1',
  heading2: 'HDS_DISPLAY_HEADING2',
  heading3: 'HDS_DISPLAY_HEADING3',
  heading4: 'HDS_DISPLAY_HEADING4',
  heading5: 'HDS_DISPLAY_HEADING5',
  heading6: 'HDS_DISPLAY_HEADING6',
  tagline: 'HDS_DISPLAY_TAGLINE',
  pageHeading: 'HDS_DISPLAY_PAGE_HEADING'
};
export const textColorMap = {
  default: 'HDS_COLOR_TEXT_DEFAULT',
  subtle: 'HDS_COLOR_TEXT_SUBTLE',
  inverse: 'HDS_COLOR_TEXT_INVERSE',
  danger: 'HDS_COLOR_TEXT_DANGER',
  success: 'HDS_COLOR_TEXT_SUCCESS'
};