import styled from 'styled-components';
import { LORAX, OBSIDIAN, BATTLESHIP, OLAF } from 'HubStyleTokens/colors';
import UIIcon from 'UIComponents/icon/UIIcon';
import UISelectableBox from 'UIComponents/button/UISelectableBox';
export const TextStyle = `
    color: ${OBSIDIAN};
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;

    &:hover {
    color: ${OBSIDIAN};
    text-decoration: none;
    }

    strong {
    font-weight: inherit;
    color: ${LORAX};
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
    }
`;
export const StyledViralContainer = styled(UISelectableBox).withConfig({
  displayName: "ViralComponents__StyledViralContainer",
  componentId: "sc-16vu88g-0"
})(["border:1px solid ", " !important;background-color:", " !important;box-shadow:none !important;&.full-size{width:100%;}"], BATTLESHIP, OLAF);
export const StyledViralText = styled.div.withConfig({
  displayName: "ViralComponents__StyledViralText",
  componentId: "sc-16vu88g-1"
})(["", ""], TextStyle);
export const StyledViralIcon = styled(UIIcon).withConfig({
  displayName: "ViralComponents__StyledViralIcon",
  componentId: "sc-16vu88g-2"
})(["vertical-align:-2px;padding:2px 2px 0 0;"]);