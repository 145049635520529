import { combineReducers } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from './auth';
import { startApp } from '../actions/AppActions';
export function useAppDispatch() {
  return useDispatch();
}
export function useAppSelector(selector) {
  return useSelector(selector);
}
export const rootReducer = combineReducers({
  auth
});
export const initialState = rootReducer(undefined, startApp());