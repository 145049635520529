import { HDS_COLOR_TEXT_DEFAULT, HDS_COLOR_TEXT_SUBTLE, HDS_COLOR_TEXT_NONESSENTIAL, HDS_COLOR_TEXT_DISABLED, HDS_COLOR_TEXT_INVERSE, HDS_COLOR_TEXT_DANGER, HDS_COLOR_TEXT_SUCCESS, HDS_FONT_WEIGHT_BODY_DEFAULT, HDS_FONT_WEIGHT_BODY_BOLD, HDS_FONT_WEIGHT_DISPLAY_LIGHT, HDS_FONT_WEIGHT_DISPLAY_MEDIUM, HDS_FONT_WEIGHT_DISPLAY_DEFAULT, HDS_FONT_WEIGHT_DISPLAY_BOLD, HDS_FONT_WEIGHT_CODE_DEFAULT, HDS_LINE_HEIGHT_BODY_DEFAULT, HDS_LINE_HEIGHT_BODY_TIGHT, HDS_LINE_HEIGHT_DISPLAY, HDS_LINE_HEIGHT_CODE, HDS_TYPE_SCALE_BODY_SMALL, HDS_TYPE_SCALE_BODY_DEFAULT, HDS_TYPE_SCALE_BODY_LARGE, HDS_TYPE_SCALE_DISPLAY_HEADING1, HDS_TYPE_SCALE_DISPLAY_HEADING2, HDS_TYPE_SCALE_DISPLAY_HEADING3, HDS_TYPE_SCALE_DISPLAY_HEADING4, HDS_TYPE_SCALE_DISPLAY_HEADING5, HDS_TYPE_SCALE_DISPLAY_HEADING6, HDS_TYPE_SCALE_CODE_DEFAULT, HDS_TEXT_BODY, HDS_TEXT_BODY_SMALL, HDS_TEXT_BODY_LARGE, HDS_TEXT_CODE, HDS_DISPLAY_HEADING1, HDS_DISPLAY_HEADING2, HDS_DISPLAY_HEADING3, HDS_DISPLAY_HEADING4, HDS_DISPLAY_HEADING5, HDS_DISPLAY_HEADING6, HDS_DISPLAY_TAGLINE, HDS_DISPLAY_PAGE_HEADING, HDS_ALERT_COLOR_BACKGROUND_DANGER, HDS_ALERT_COLOR_BACKGROUND_INFO, HDS_ALERT_COLOR_BACKGROUND_WARNING, HDS_ALERT_COLOR_BACKGROUND_SUCCESS, HDS_ALERT_COLOR_BACKGROUND_TIP, HDS_ALERT_COLOR_BACKGROUND_DANGER_LOUD, HDS_ALERT_COLOR_BACKGROUND_INFO_LOUD, HDS_ALERT_COLOR_BACKGROUND_WARNING_LOUD, HDS_CONTAINER_RADIUS_BORDER, HDS_CARD_SHADOW, HDS_BORDER_SUBTLE, HDS_CONTAINER_COLOR_BACKGROUND, HDS_ALERT_BORDER_DANGER, HDS_ALERT_BORDER_INFO, HDS_ALERT_BORDER_WARNING, HDS_ALERT_BORDER_SUCCESS, HDS_ALERT_BORDER_TIP } from 'hds-tokens';
import { HDS_FONT_FAMILY_DEFAULT, HDS_FONT_FAMILY_CODE } from 'hds-tokens/web-tokens/base-tokens';
import { HDS_SPACE_NONE, HDS_SPACE_50, HDS_SPACE_100, HDS_SPACE_150, HDS_SPACE_200, HDS_SPACE_250, HDS_SPACE_300, HDS_SPACE_350, HDS_SPACE_400, HDS_SPACE_450, HDS_SPACE_500, HDS_SPACE_600, HDS_SPACE_700, HDS_SPACE_800, HDS_SPACE_900, HDS_SPACE_1000, HDS_SPACE_1100, HDS_SPACE_1200, HDS_SPACE_1300, HDS_SPACE_1400, HDS_SPACE_1500 } from 'hds-tokens/web-tokens/semantic-tokens';
export const DEFAULT_THEME = {
  UNSTABLE_HDS: {
    HDS_COLOR_TEXT_DEFAULT,
    HDS_COLOR_TEXT_SUBTLE,
    HDS_COLOR_TEXT_NONESSENTIAL,
    HDS_COLOR_TEXT_DISABLED,
    HDS_COLOR_TEXT_INVERSE,
    HDS_COLOR_TEXT_DANGER,
    HDS_COLOR_TEXT_SUCCESS,
    HDS_FONT_WEIGHT_BODY_DEFAULT,
    HDS_FONT_WEIGHT_BODY_BOLD,
    HDS_FONT_WEIGHT_DISPLAY_LIGHT,
    HDS_FONT_WEIGHT_DISPLAY_MEDIUM,
    HDS_FONT_WEIGHT_DISPLAY_DEFAULT,
    HDS_FONT_WEIGHT_DISPLAY_BOLD,
    HDS_FONT_WEIGHT_CODE_DEFAULT,
    HDS_LINE_HEIGHT_BODY_DEFAULT,
    HDS_LINE_HEIGHT_BODY_TIGHT,
    HDS_LINE_HEIGHT_DISPLAY,
    HDS_LINE_HEIGHT_CODE,
    HDS_TYPE_SCALE_BODY_SMALL,
    HDS_TYPE_SCALE_BODY_DEFAULT,
    HDS_TYPE_SCALE_BODY_LARGE,
    HDS_TYPE_SCALE_DISPLAY_HEADING1,
    HDS_TYPE_SCALE_DISPLAY_HEADING2,
    HDS_TYPE_SCALE_DISPLAY_HEADING3,
    HDS_TYPE_SCALE_DISPLAY_HEADING4,
    HDS_TYPE_SCALE_DISPLAY_HEADING5,
    HDS_TYPE_SCALE_DISPLAY_HEADING6,
    HDS_TYPE_SCALE_CODE_DEFAULT,
    HDS_TEXT_BODY,
    HDS_TEXT_BODY_LARGE,
    HDS_TEXT_BODY_SMALL,
    HDS_TEXT_CODE,
    HDS_DISPLAY_HEADING1,
    HDS_DISPLAY_HEADING2,
    HDS_DISPLAY_HEADING3,
    HDS_DISPLAY_HEADING4,
    HDS_DISPLAY_HEADING5,
    HDS_DISPLAY_HEADING6,
    HDS_DISPLAY_TAGLINE,
    HDS_DISPLAY_PAGE_HEADING,
    HDS_FONT_FAMILY_DEFAULT,
    HDS_FONT_FAMILY_CODE,
    HDS_SPACE_NONE,
    HDS_SPACE_50,
    HDS_SPACE_100,
    HDS_SPACE_150,
    HDS_SPACE_200,
    HDS_SPACE_250,
    HDS_SPACE_300,
    HDS_SPACE_350,
    HDS_SPACE_400,
    HDS_SPACE_450,
    HDS_SPACE_500,
    HDS_SPACE_600,
    HDS_SPACE_700,
    HDS_SPACE_800,
    HDS_SPACE_900,
    HDS_SPACE_1000,
    HDS_SPACE_1100,
    HDS_SPACE_1200,
    HDS_SPACE_1300,
    HDS_SPACE_1400,
    HDS_SPACE_1500,
    HDS_ALERT_COLOR_BACKGROUND_DANGER,
    HDS_ALERT_COLOR_BACKGROUND_INFO,
    HDS_ALERT_COLOR_BACKGROUND_WARNING,
    HDS_ALERT_COLOR_BACKGROUND_SUCCESS,
    HDS_ALERT_COLOR_BACKGROUND_TIP,
    HDS_ALERT_COLOR_BACKGROUND_DANGER_LOUD,
    HDS_ALERT_COLOR_BACKGROUND_INFO_LOUD,
    HDS_ALERT_COLOR_BACKGROUND_WARNING_LOUD,
    HDS_CONTAINER_RADIUS_BORDER,
    HDS_CARD_SHADOW,
    HDS_BORDER_SUBTLE,
    HDS_CONTAINER_COLOR_BACKGROUND,
    HDS_ALERT_BORDER_DANGER,
    HDS_ALERT_BORDER_INFO,
    HDS_ALERT_BORDER_WARNING,
    HDS_ALERT_BORDER_SUCCESS,
    HDS_ALERT_BORDER_TIP
  }
};