import enviro from 'enviro';
import { SignupUrlGenerator } from 'signup-ui-url-generator/SignupUrlGenerator';
import { EMBEDDED_VIRAL_LINK_URL } from '../constants/EmbeddedViralLinks';
import { getIsQaFromDom, getHref } from './domainUtils';

const isHsFormsQaDomain = () => {
  const windowHref = getHref();
  const isSourcelessIframe = !windowHref;

  if (isSourcelessIframe) {
    return false;
  }

  const hostName = new URL(windowHref).hostname;
  return /hsformsqa\.com/.test(hostName);
};

const isFormsQaEnv = () => {
  const isFormsQa = getIsQaFromDom();
  return isHsFormsQaDomain() || isFormsQa;
};

export const getIsQa = () => enviro.isQa() || isFormsQaEnv();

function getApiUrl(qa = getIsQa()) {
  const maybeQaSuffix = qa ? 'qa' : '';
  return `https://api.hubspot${maybeQaSuffix}.com`;
}

function getAppUrl(qa = getIsQa(), useLocal = false) {
  const lb = useLocal ? 'local' : 'app';
  const maybeQaSuffix = qa ? 'qa' : '';
  return `https://${lb}.hubspot${maybeQaSuffix}.com`;
}

function getUrlWithParams(url, params) {
  const result = new URL(url);
  Object.keys(params).forEach(key => {
    result.searchParams.set(key, params[key]);
  });
  return result.toString();
}

export function getSignupUrl(flow, viralType, deviceId, qa = enviro.isQa(), additionalParams) {
  const query = Object.assign({
    uuid: deviceId,
    utm_medium: 'virality',
    utm_campaign: `hubspot-${viralType}-virality`,
    use_uuid: '1'
  }, additionalParams);
  return new SignupUrlGenerator(flow, {
    query,
    env: qa ? 'qa' : 'prod'
  }).getUrl();
}
export function getUrlWithViralParams(intermediatePage, viralType, deviceId, additionalParams) {
  return getUrlWithParams(intermediatePage, Object.assign({
    uuid: deviceId,
    utm_medium: 'virality',
    utm_campaign: `hubspot-${viralType}-virality`,
    use_uuid: '1'
  }, additionalParams));
}
export function getRedirectTrackingUrl({
  viralLinkType,
  deviceId,
  portalId,
  environment = enviro.getShort(),
  redirectPage
}) {
  const query = {
    u: redirectPage,
    deviceId,
    viralLinkType
  };

  if (portalId) {
    query.hubId = portalId.toString();
  }

  return getUrlWithParams(`${getApiUrl(environment === 'qa')}/viral-links/v1/click-tracking`, query);
}
export function getTrackingPixelUrl({
  viralLinkType,
  deviceId,
  portalId,
  environment,
  userId,
  isExternalLink
}) {
  const environmentOrDefault = environment || enviro.getShort();
  const url = `${getApiUrl(environmentOrDefault === 'qa')}/viral-links/v1/tracking`;
  const query = {
    viralLinkType,
    deviceId
  };

  if (isExternalLink) {
    query.isExternalLink = 'true';
  }

  if (portalId) {
    query.hubId = portalId.toString();
  } else if (userId) {
    query.userId = userId.toString();
  }

  return getUrlWithParams(url, query);
}
export const getViralLinkIframeSrc = (linkType, queryParameters) => {
  const iframeSrc = new URL(`${getAppUrl()}${EMBEDDED_VIRAL_LINK_URL}${linkType}`);
  Object.keys(queryParameters).forEach(key => {
    const paramValue = `${queryParameters[key]}`;
    iframeSrc.searchParams.append(key, paramValue);
  });
  return iframeSrc.toString();
};