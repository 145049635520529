import { Domain } from 'signup-ui-lego-core/constants/Domain';
import { Locale } from 'signup-ui-lego-core/constants/Locale';
import { Subdomain } from 'signup-ui-lego-core/constants/Subdomain';
import { Subfolder } from 'signup-ui-lego-core/constants/Subfolder';
export const DomainLocale = new Map([[Domain.Deutch, Locale.de], [Domain.English, Locale.en], [Domain.Japanese, Locale.ja], [Domain.Spanish, Locale.es], [Domain.French, Locale.fr]]);
export const SubfolderLocale = new Map([[Subfolder.Deutch, Locale.de], [Subfolder.Japanese, Locale.ja], [Subfolder.Spanish, Locale.es], [Subfolder.French, Locale.fr], [Subfolder.Portuguese, Locale.ptBr]]);
export const SubdomainLocale = new Map([[Subdomain.Br, Locale.ptBr]]);
export const getSubfolderLocale = domain => {
  return SubfolderLocale.get(domain);
};
export const getDomainLocale = domain => {
  return DomainLocale.get(domain);
};
export const getSubdomainLocale = subdomain => {
  return SubdomainLocale.get(subdomain);
};
/**
 * Get a language from a given URL.
 *
 */

export const getLocaleFromUrl = url => {
  if (!url || typeof url !== 'string' || url.trim().length === 0) {
    return Locale.en;
  }

  try {
    url = new URL(url);

    if (!url.hostname.includes('hubspot')) {
      return Locale.en;
    }

    const tld = url.hostname.slice(url.hostname.lastIndexOf('.') + 1, url.hostname.length);
    const tldLocale = getDomainLocale(tld);
    const subdomain = url.hostname.slice(0, url.hostname.indexOf('.'));
    const subdomainLocale = getSubdomainLocale(subdomain);
    const hubsSignupUrl = new URLSearchParams(window.location.href).get('hubs_signup-url');
    const subfolder = hubsSignupUrl && hubsSignupUrl.split('/')[1];
    return getSubfolderLocale(subfolder) || subdomainLocale || tldLocale || Locale.en;
  } catch (exc) {
    return Locale.en;
  }
};