// Text shouldn't have any sort of spacing. Instead, the HDSSpacer component should be used to add spacing.
const baseStyles = {
  boxSizing: 'border-box',
  margin: '0px',
  padding: '0px'
};
/**
 * Generates text styles based on the token props provided.
 * @param style - Styles to be generated.
 * @param style.fontSize - The font size to be applied.
 * @param style.fontWeight - The font weight to be applied.
 * @param style.fontFamily - The font family to be applied.
 * @param style.lineHeight - The line height to be applied.
 * @param style.color - The text color to be applied.
 * @param style.display - The display value to be applied.
 * @param style.textTransform - The text transformation to be applied.
 * @param style.textAlign - The text alignment to be applied.
 * @param style.wordBreak - The word break value to be applied.
 * @returns CSS styles to be applied to the component.
 */

export const generateTextStyles = ({
  fontSize,
  fontWeight,
  fontFamily,
  lineHeight,
  color,
  display,
  textTransform = 'none',
  // Defaults to none in order to override any existing text-transform styles coming from `HubStyle`
  textAlign,
  wordBreak
}) => {
  return Object.assign({}, baseStyles, {
    fontSize,
    fontWeight,
    fontFamily,
    lineHeight,
    color,
    display,
    textTransform,
    textAlign,
    wordBreak
  });
};