import { getHistory } from '../utils/history';
/**
 * Normalize a URL.
 * - If it has a relative protocol, add the defaultProtocol (https:)
 * - If if does not contain a protocol, add the defaultProtocol (https:)
 *
 * @param {string} url The URL to be normalized.
 * @param {Object} options The normalization options.
 * @returns {string} The normalized URL.
 */

export const normalizeUrl = url => {
  const hasRelativeProtocol = url.substring(0, 2) === '//';

  if (hasRelativeProtocol) {
    url = `https:${url}`;
  }

  if (url && url.substring(0, 8) !== 'https://' && url.substring(0, 7) !== 'http://') {
    url = `https://${url}`;
  }

  return url;
};
export const getPreferredReferrer = () => {
  const queryParams = new URLSearchParams(getHistory().location.search);
  const preReferrer = queryParams.get('preReferrer');
  const hubsSignupUrlReferrer = queryParams.get('hubs_signup-url');
  const documentReferrer = document.referrer;
  const referrer = normalizeUrl(preReferrer || hubsSignupUrlReferrer || documentReferrer || '');
  return referrer;
};