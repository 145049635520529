/**
 * THIS IS AN AUTOGENERATED FILE.
 */
export const HDS_RADIUS_BORDER_0 = "0";
export const HDS_RADIUS_BORDER_3 = "3px";
export const HDS_RADIUS_BORDER_6 = "6px";
export const HDS_RADIUS_BORDER_20 = "20px";
export const HDS_RADIUS_BORDER_24 = "24px";
export const HDS_RADIUS_BORDER_500 = "500px";
export const HDS_RADIUS_BORDER_50PERCENT = "50%";
export const HDS_WIDTH_BORDER_0 = "0";
export const HDS_WIDTH_BORDER_1 = "1px";
export const HDS_WIDTH_BORDER_2 = "2px";
export const HDS_COLOR_LORAX_DEFAULT = "#FF7A59";
export const HDS_COLOR_LORAX_DARK = "#E66E50";
export const HDS_COLOR_LORAX_MEDIUM = "#FFBCAC";
export const HDS_COLOR_LORAX_LIGHT = "#FFF1EE";
export const HDS_COLOR_CALYPSO_DEFAULT = "#00a4bd";
export const HDS_COLOR_CALYPSO_DARK = "#0091ae";
export const HDS_COLOR_CALYPSO_MEDIUM = "#7fd1de";
export const HDS_COLOR_CALYPSO_LIGHT = "#e5f5f8";
export const HDS_COLOR_CANDY_APPLE_DEFAULT = "#F2545B";
export const HDS_COLOR_CANDY_APPLE_DARK = "#D94C53";
export const HDS_COLOR_CANDY_APPLE_MEDIUM = "#F8A9AD";
export const HDS_COLOR_CANDY_APPLE_LIGHT = "#FDEDEE";
export const HDS_COLOR_MARIGOLD_DEFAULT = "#F5C26B";
export const HDS_COLOR_MARIGOLD_DARK = "#DBAE60";
export const HDS_COLOR_MARIGOLD_MEDIUM = "#FAE0B5";
export const HDS_COLOR_MARIGOLD_LIGHT = "#FEF8F0";
export const HDS_COLOR_OZ_DEFAULT = "#00BDA5";
export const HDS_COLOR_OZ_DARK = "#00A38D";
export const HDS_COLOR_OZ_MEDIUM = "#7FDED2";
export const HDS_COLOR_OZ_LIGHT = "#E5F8F6";
export const HDS_COLOR_NORMAN_DEFAULT = "#F2547D";
export const HDS_COLOR_NORMAN_DARK = "#D94C71";
export const HDS_COLOR_NORMAN_MEDIUM = "#F9AABE";
export const HDS_COLOR_NORMAN_LIGHT = "#FDEDF2";
export const HDS_COLOR_SORBET_DEFAULT = "#FF8F59";
export const HDS_COLOR_SORBET_DARK = "#E68250";
export const HDS_COLOR_SORBET_MEDIUM = "#FFC7AC";
export const HDS_COLOR_SORBET_LIGHT = "#FFF3EE";
export const HDS_COLOR_THUNDERDOME_DEFAULT = "#6A78D1";
export const HDS_COLOR_THUNDERDOME_DARK = "#5E6AB8";
export const HDS_COLOR_THUNDERDOME_MEDIUM = "#B4BBE8";
export const HDS_COLOR_THUNDERDOME_LIGHT = "#F0F1FA";
export const HDS_COLOR_NEUTRAL_FLAT_EARTH = "#253342";
export const HDS_COLOR_NEUTRAL_PANTERA = "#2D3E50";
export const HDS_COLOR_NEUTRAL_HEFFALUMP = "#425B76";
export const HDS_COLOR_NEUTRAL_SLINKY = "#516F90";
export const HDS_COLOR_NEUTRAL_EERIE = "#7C98B6";
export const HDS_COLOR_NEUTRAL_FLINT = "#99ACC2";
export const HDS_COLOR_NEUTRAL_BATTLESHIP = "#CBD6E2";
export const HDS_COLOR_NEUTRAL_GREAT_WHITE = "#DFE3EB";
export const HDS_COLOR_NEUTRAL_KOALA = "#EAF0F6";
export const HDS_COLOR_NEUTRAL_GYPSUM = "#F5F8FA";
export const HDS_COLOR_NEUTRAL_OLAF = "#ffffff";
export const HDS_COLOR_NEUTRAL_MONOLITH = "#000000";
export const HDS_COLOR_OBSIDIAN = "#33475b";
export const HDS_FONT_FAMILY_CODE = "Source Code Pro, Consolas, Monaco, Courier New, monospace";
export const HDS_FONT_FAMILY_DEFAULT = "Lexend Deca, Helvetica, Arial, sans-serif";
export const HDS_FONT_FAMILY_BODY_FALLBACK = "Helvetica, Arial, sans-serif";
export const HDS_FONT_FAMILY_MONOSPACE_FALLBACK = "Consolas, Monaco, Courier New, monospace";
export const HDS_FONT_SIZE_12 = "12px";
export const HDS_FONT_SIZE_14 = "14px";
export const HDS_FONT_SIZE_16 = "16px";
export const HDS_FONT_SIZE_20 = "20px";
export const HDS_FONT_SIZE_22 = "22px";
export const HDS_FONT_SIZE_24 = "24px";
export const HDS_FONT_SIZE_32 = "32px";
export const HDS_FONT_SIZE_55 = "55px";
export const HDS_FONT_WEIGHT_300 = "300";
export const HDS_FONT_WEIGHT_500 = "500";
export const HDS_FONT_WEIGHT_600 = "600";
export const HDS_FONT_WEIGHT_700 = "700";
export const HDS_LINE_HEIGHT_18 = "18px";
export const HDS_LINE_HEIGHT_22 = "22px";
export const HDS_LINE_HEIGHT_24 = "24px";
export const HDS_LINE_HEIGHT_NORMAL = "1.2";
export const HDS_LINE_HEIGHT_ONE_FOUR = "1.4";
export const HDS_TIMING_DURATION_100 = "100ms";
export const HDS_TIMING_DURATION_150 = "150ms";
export const HDS_TIMING_DURATION_200 = "200ms";
export const HDS_TIMING_DURATION_250 = "250ms";
export const HDS_TIMING_DURATION_300 = "300ms";
export const HDS_TIMING_DURATION_350 = "350ms";
export const HDS_TIMING_FUNCTION_EASE = "cubic-bezier(0.25, 0.1, 0.25, 1)";
export const HDS_TIMING_FUNCTION_LINEAR = "cubic-bezier(0, 0, 1, 1)";
export const HDS_TIMING_FUNCTION_EASE_IN = "cubic-bezier(0.42, 0, 1, 1)";
export const HDS_TIMING_FUNCTION_EASE_OUT = "cubic-bezier(0, 0, 0.58, 1)";
export const HDS_TIMING_FUNCTION_EASE_IN_OUT = "cubic-bezier(0.42, 0, 0.58, 1)";
export const HDS_SPACE_0 = "0";
export const HDS_SPACE_2 = "2px";
export const HDS_SPACE_4 = "4px";
export const HDS_SPACE_6 = "6px";
export const HDS_SPACE_8 = "8px";
export const HDS_SPACE_10 = "10px";
export const HDS_SPACE_12 = "12px";
export const HDS_SPACE_14 = "14px";
export const HDS_SPACE_16 = "16px";
export const HDS_SPACE_18 = "18px";
export const HDS_SPACE_20 = "20px";
export const HDS_SPACE_24 = "24px";
export const HDS_SPACE_28 = "28px";
export const HDS_SPACE_32 = "32px";
export const HDS_SPACE_36 = "36px";
export const HDS_SPACE_40 = "40px";
export const HDS_SPACE_44 = "44px";
export const HDS_SPACE_48 = "48px";
export const HDS_SPACE_52 = "52px";
export const HDS_SPACE_56 = "56px";
export const HDS_SPACE_60 = "60px";
export const HDS_TEXT_TRANSFORM_NONE = "none";
export const HDS_TEXT_TRANSFORM_UPPERCASE = "uppercase";