/**
 * Attaches the correct TypeScript type to the HDS theme.
 * This function is used instead of the Styled Components-recommended type override.
 * The HubSpot FE build system doesn't allow type overrides.
 * @see [Styled Components Recommendation](https://styled-components.com/docs/api#create-a-declarations-file)
 * @see [HubSpot TypeScript Declarations Docs](https://product.hubteam.com/docs/frontend/docs/typescript-at-hubspot.html#declarations-dts-declare)
 * @example
 * ```
 * const MyStyledComponent = styled.div<MyStyledComponentProps>`
 *   color: ${({ theme }) => theme.darkColor};
 *   backgroundColor: ${({ theme }) => theme.lightColor};
 * `;
 * ```
 * @param theme - The theme coming from `HDSAppProvider`.
 * @returns The theme with proper TypeScript types, or an error if the theme doesn't exist.
 */
export function readHDSTheme(theme) {
  if (!theme.UNSTABLE_HDS) {
    throw new Error("HDSAppProvider is missing. All apps must have their root node wrapped in an HDSAppProvider. If you're using the UIApplication component at the root, the HDSAppProvider component comes bundled.");
  }

  return theme;
}