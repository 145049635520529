/**
 * THIS IS AN AUTOGENERATED FILE.
 */
export const HDS_RADIUS_BORDER_NONE = "0";
export const HDS_RADIUS_BORDER_DEFAULT = "3px";
export const HDS_RADIUS_BORDER_CIRCLE = "50%";
export const HDS_WIDTH_BORDER_NONE = "0";
export const HDS_WIDTH_BORDER_DEFAULT = "1px";
export const HDS_COLOR_BORDER_DEFAULT = "#CBD6E2";
export const HDS_COLOR_BORDER_SUBTLE = "#DFE3EB";
export const HDS_COLOR_BORDER_TABLE = "#DFE3EB";
export const HDS_COLOR_BORDER_SELECTABLE = "#7fd1de";
export const HDS_COLOR_BORDER_DROPZONE_IDLE = "#516F90";
export const HDS_COLOR_BORDER_DROPZONE_HOVER = "#00a4bd";
export const HDS_COLOR_BORDER_DROPZONE_PROCESSION = "#F5F8FA";
export const HDS_COLOR_BORDER_DROPZONE_ERROR = "#D94C53";
export const HDS_COLOR_BORDER_ACTIVE = "#00d0e480";
export const HDS_COLOR_TEXT_DEFAULT = "#33475b";
export const HDS_COLOR_TEXT_SUBTLE = "#516F90";
export const HDS_COLOR_TEXT_NONESSENTIAL = "#7c98b6";
export const HDS_COLOR_TEXT_DISABLED = "#b0c1d4";
export const HDS_COLOR_TEXT_INVERSE = "#ffffff";
export const HDS_COLOR_TEXT_DANGER = "#F2545B";
export const HDS_COLOR_TEXT_SUCCESS = "#00BDA5";
export const HDS_COLOR_SURFACE_DEFAULT = "#ffffff";
export const HDS_COLOR_SURFACE_ELEVATION1 = "#F5F8FA";
export const HDS_COLOR_SURFACE_TRANSPARENT = "rgba(0, 0, 0, 0)";
export const HDS_FONT_WEIGHT_BODY_DEFAULT = "300";
export const HDS_FONT_WEIGHT_BODY_BOLD = "600";
export const HDS_FONT_WEIGHT_DISPLAY_LIGHT = "300";
export const HDS_FONT_WEIGHT_DISPLAY_MEDIUM = "500";
export const HDS_FONT_WEIGHT_DISPLAY_DEFAULT = "600";
export const HDS_FONT_WEIGHT_DISPLAY_BOLD = "700";
export const HDS_FONT_WEIGHT_CODE_DEFAULT = "500";
export const HDS_LINE_HEIGHT_BODY_DEFAULT = "24px";
export const HDS_LINE_HEIGHT_BODY_TIGHT = "18px";
export const HDS_LINE_HEIGHT_DISPLAY = "1.2";
export const HDS_LINE_HEIGHT_CODE = "24px";
export const HDS_SPACE_50 = "2px";
export const HDS_SPACE_100 = "4px";
export const HDS_SPACE_150 = "6px";
export const HDS_SPACE_200 = "8px";
export const HDS_SPACE_250 = "10px";
export const HDS_SPACE_300 = "12px";
export const HDS_SPACE_350 = "14px";
export const HDS_SPACE_400 = "16px";
export const HDS_SPACE_450 = "18px";
export const HDS_SPACE_500 = "20px";
export const HDS_SPACE_600 = "24px";
export const HDS_SPACE_700 = "28px";
export const HDS_SPACE_800 = "32px";
export const HDS_SPACE_900 = "36px";
export const HDS_SPACE_1000 = "40px";
export const HDS_SPACE_1100 = "44px";
export const HDS_SPACE_1200 = "48px";
export const HDS_SPACE_1300 = "52px";
export const HDS_SPACE_1400 = "56px";
export const HDS_SPACE_1500 = "60px";
export const HDS_SPACE_NONE = "0";
export const HDS_ACCORDION_ICON_SIZE_WIDTH_MEDIUM = "9px";
export const HDS_ACCORDION_ICON_SIZE_WIDTH_EXTRA_LARGE = "11px";
export const HDS_ACCORDION_ICON_SIZE_WIDTH_LARGE = "11px";
export const HDS_ACCORDION_ICON_SIZE_WIDTH_SMALL = "7px";
export const HDS_ACCORDION_ICON_SIZE_WIDTH_EXTRA_SMALL = "5px";
export const HDS_ACCORDION_ICON_SIZE_HEIGHT_MEDIUM = "16px";
export const HDS_ACCORDION_ICON_SIZE_HEIGHT_EXTRA_LARGE = "20px";
export const HDS_ACCORDION_ICON_SIZE_HEIGHT_LARGE = "20px";
export const HDS_ACCORDION_ICON_SIZE_HEIGHT_SMALL = "12px";
export const HDS_ACCORDION_ICON_SIZE_HEIGHT_EXTRA_SMALL = "10px";
export const HDS_ACCORDION_TITLE_SPACE_PADDING_INLINE_START_MEDIUM = "24px";
export const HDS_ACCORDION_TITLE_SPACE_PADDING_INLINE_START_EXTRA_LARGE = "28px";
export const HDS_ACCORDION_TITLE_SPACE_PADDING_INLINE_START_LARGE = "28px";
export const HDS_ACCORDION_TITLE_SPACE_PADDING_INLINE_START_SMALL = "20px";
export const HDS_ACCORDION_TITLE_SPACE_PADDING_INLINE_START_EXTRA_SMALL = "16px";
export const HDS_ACCORDION_TITLE_SPACE_PADDING_BLOCK_END_MEDIUM = "16px";
export const HDS_ACCORDION_TITLE_SPACE_PADDING_BLOCK_END_EXTRA_LARGE = "16px";
export const HDS_ACCORDION_TITLE_SPACE_PADDING_BLOCK_END_LARGE = "16px";
export const HDS_ACCORDION_TITLE_SPACE_PADDING_BLOCK_END_SMALL = "16px";
export const HDS_ACCORDION_TITLE_SPACE_PADDING_BLOCK_END_EXTRA_SMALL = "8px";
export const HDS_ACCORDION_CONTENT_SPACE_PADDING_INLINE_START_MEDIUM = "24px";
export const HDS_ACCORDION_CONTENT_SPACE_PADDING_INLINE_START_EXTRA_LARGE = "28px";
export const HDS_ACCORDION_CONTENT_SPACE_PADDING_INLINE_START_LARGE = "28px";
export const HDS_ACCORDION_CONTENT_SPACE_PADDING_INLINE_START_SMALL = "20px";
export const HDS_ACCORDION_CONTENT_SPACE_PADDING_INLINE_START_EXTRA_SMALL = "16px";
export const HDS_ACCORDION_SPACE_GAP_INLINE = "12px";
export const HDS_ACTION_COLOR_TEXT_DEFAULT_IDLE = "#0091ae";
export const HDS_ACTION_COLOR_TEXT_DEFAULT_HOVER = "#007a8c";
export const HDS_ACTION_COLOR_TEXT_DEFAULT_PRESSED = "#7fd1de";
export const HDS_ACTION_COLOR_TEXT_DEFAULT_VISITED = "#0091ae";
export const HDS_ACTION_COLOR_TEXT_VISITED = "#33475b";
export const HDS_ACTION_COLOR_TEXT_DISABLED = "#b0c1d4";
export const HDS_ACTION_COLOR_TEXT_DANGER_IDLE = "#F2545B";
export const HDS_ACTION_COLOR_TEXT_DANGER_HOVER = "#D94C53";
export const HDS_ACTION_COLOR_TEXT_DANGER_PRESSED = "#D94C53";
export const HDS_ACTION_COLOR_TEXT_DANGER_VISITED = "#F2545B";
export const HDS_ACTION_COLOR_TEXT_ON_SURFACE_IDLE = "#33475b";
export const HDS_ACTION_COLOR_TEXT_ON_SURFACE_HOVER = "#33475b";
export const HDS_ACTION_COLOR_TEXT_ON_SURFACE_PRESSED = "#33475b";
export const HDS_ACTION_COLOR_TEXT_ON_SURFACE_VISITED = "#33475b";
export const HDS_ACTION_COLOR_TEXT_INVERSE_IDLE = "#ffffff";
export const HDS_ACTION_COLOR_TEXT_INVERSE_HOVER = "#7fd1de";
export const HDS_ACTION_COLOR_TEXT_INVERSE_PRESSED = "#EAF0F6";
export const HDS_ACTION_COLOR_TEXT_INVERSE_VISITED = "#ffffff";
export const HDS_ACTION_COLOR_ICON_DEFAULT = "#7C98B6";
export const HDS_ACTION_COLOR_ICON_ON_SURFACE = "#33475b";
export const HDS_ACTION_COLOR_ICON_INVERSE = "#ffffff";
export const HDS_ACTION_COLOR_ICON_EXTERNAL = "#7C98B6";
export const HDS_ACTION_FONT_WEIGHT_DEFAULT = "600";
export const HDS_ACTION_SPACE_GAP_DEFAULT = "0.5em";
export const HDS_ACTION_SPACE_GAP_EXTERNAL_ICON = "0.25em";
export const HDS_ACTION_TYPOGRAPHY_DEFAULT = {
  "fontWeight": "600",
  "lineHeight": "inherit",
  "textDecoration": "none",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontSize": "14px"
};
export const HDS_ACTION_TYPOGRAPHY_UNDERLINE = {
  "fontWeight": "600",
  "lineHeight": "inherit",
  "textDecoration": "underline",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontSize": "14px"
};
export const HDS_ACTION_TYPOGRAPHY_SMALL = {
  "fontWeight": "600",
  "lineHeight": "inherit",
  "textDecoration": "none",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontSize": "12px"
};
export const HDS_ALERT_COLOR_BACKGROUND_DANGER = "#FDEDEE";
export const HDS_ALERT_COLOR_BACKGROUND_INFO = "#e5f5f8";
export const HDS_ALERT_COLOR_BACKGROUND_WARNING = "#FEF8F0";
export const HDS_ALERT_COLOR_BACKGROUND_SUCCESS = "#E5F8F6";
export const HDS_ALERT_COLOR_BACKGROUND_TIP = "#ffffff";
export const HDS_ALERT_COLOR_BACKGROUND_DANGER_LOUD = "#FFF1EE";
export const HDS_ALERT_COLOR_BACKGROUND_INFO_LOUD = "#e5f5f8";
export const HDS_ALERT_COLOR_BACKGROUND_WARNING_LOUD = "#fdf3e1";
export const HDS_ALERT_COLOR_BACKGROUND_PROMO = "linear-gradient(89deg, #FF7A59 0%, #F2547D 100% )";
export const HDS_ALERT_COLOR_BORDER_DANGER = "#F8A9AD";
export const HDS_ALERT_COLOR_BORDER_INFO = "#7fd1de";
export const HDS_ALERT_COLOR_BORDER_WARNING = "#FAE0B5";
export const HDS_ALERT_COLOR_BORDER_SUCCESS = "#7FDED2";
export const HDS_ALERT_COLOR_BORDER_TIP = "#7fd1de";
export const HDS_ALERT_MIN_HEIGHT_COMPACT = "44px";
export const HDS_ALERT_MIN_HEIGHT_DEFAULT = "60px";
export const HDS_ALERT_WIDTH_BORDER_DEFAULT = "1px";
export const HDS_ALERT_WIDTH_BORDER_NONE = "0";
export const HDS_ALERT_TITLE = {
  "lineHeight": "1.2",
  "fontWeight": "600",
  "fontSize": "16px",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif"
};
export const HDS_ALERT_TEXT = {
  "lineHeight": "1.4",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "fontSize": "14px"
};
export const HDS_ALERT_SPACE_PADDING_BLOCK_DEFAULT = "8px";
export const HDS_ALERT_SPACE_PADDING_INLINE_DEFAULT = "20px";
export const HDS_ALERT_SPACE_GAP_INLINE_DEFAULT = "20px";
export const HDS_ALERT_SPACE_GAP_BLOCK_DEFAULT = "8px";
export const HDS_ALERT_BORDER_DANGER = "1px solid #F8A9AD";
export const HDS_ALERT_BORDER_INFO = "1px solid #7fd1de";
export const HDS_ALERT_BORDER_WARNING = "1px solid #FAE0B5";
export const HDS_ALERT_BORDER_SUCCESS = "1px solid #7FDED2";
export const HDS_ALERT_BORDER_TIP = "1px solid #7fd1de";
export const HDS_ALERT_FLOATING_SHADOW = "0 1px 4px 2px rgba(66,91,118,.06)";
export const HDS_BORDER_DEFAULT = "1px solid #CBD6E2";
export const HDS_BORDER_SUBTLE = "1px solid #DFE3EB";
export const HDS_BUTTON_COLOR_BACKGROUND_PRIMARY_IDLE = "#FF7A59";
export const HDS_BUTTON_COLOR_BACKGROUND_PRIMARY_HOVER = "#ff8f73";
export const HDS_BUTTON_COLOR_BACKGROUND_PRIMARY_PRESSED = "#E66E50";
export const HDS_BUTTON_COLOR_BACKGROUND_NEUTRAL_DEFAULT_IDLE = "#EAF0F6";
export const HDS_BUTTON_COLOR_BACKGROUND_NEUTRAL_DEFAULT_HOVER = "#F5F8FA";
export const HDS_BUTTON_COLOR_BACKGROUND_NEUTRAL_DEFAULT_PRESSED = "#CBD6E2";
export const HDS_BUTTON_COLOR_BACKGROUND_NEUTRAL_DARK_IDLE = "#425B76";
export const HDS_BUTTON_COLOR_BACKGROUND_NEUTRAL_DARK_HOVER = "#516F90";
export const HDS_BUTTON_COLOR_BACKGROUND_NEUTRAL_DARK_PRESSED = "#33475b";
export const HDS_BUTTON_COLOR_BACKGROUND_NEUTRAL_LIGHT_IDLE = "#ffffff";
export const HDS_BUTTON_COLOR_BACKGROUND_NEUTRAL_LIGHT_HOVER = "#F5F8FA";
export const HDS_BUTTON_COLOR_BACKGROUND_NEUTRAL_LIGHT_PRESSED = "#CBD6E2";
export const HDS_BUTTON_COLOR_BACKGROUND_SECONDARY_IDLE = "#ffffff";
export const HDS_BUTTON_COLOR_BACKGROUND_SECONDARY_HOVER = "#fff8f6";
export const HDS_BUTTON_COLOR_BACKGROUND_SECONDARY_PRESSED = "#ffebe6";
export const HDS_BUTTON_COLOR_BACKGROUND_DANGER_IDLE = "#F2545B";
export const HDS_BUTTON_COLOR_BACKGROUND_DANGER_HOVER = "#ff5962";
export const HDS_BUTTON_COLOR_BACKGROUND_DANGER_PRESSED = "#D94C53";
export const HDS_BUTTON_COLOR_BACKGROUND_WHITE_IDLE = "#ffffff";
export const HDS_BUTTON_COLOR_BACKGROUND_WHITE_HOVER = "rgba(255, 255, 255, 0.9)";
export const HDS_BUTTON_COLOR_BACKGROUND_WHITE_PRESSED = "rgba(255, 255, 255, 0.8)";
export const HDS_BUTTON_COLOR_BACKGROUND_GHOST_IDLE = "rgba(255, 255, 255, 0)";
export const HDS_BUTTON_COLOR_BACKGROUND_GHOST_HOVER = "rgba(255, 255, 255, 0.05)";
export const HDS_BUTTON_COLOR_BACKGROUND_GHOST_PRESSED = "rgba(255, 255, 255, 0.10)";
export const HDS_BUTTON_COLOR_BACKGROUND_DISABLED = "#EAF0F6";
export const HDS_BUTTON_COLOR_BACKGROUND_TRANSPARENT = "rgba(0, 0, 0, 0)";
export const HDS_BUTTON_COLOR_BORDER_PRIMARY_IDLE = "#FF7A59";
export const HDS_BUTTON_COLOR_BORDER_PRIMARY_HOVER = "#ff8f73";
export const HDS_BUTTON_COLOR_BORDER_PRIMARY_PRESSED = "#E66E50";
export const HDS_BUTTON_COLOR_BORDER_NEUTRAL_DEFAULT_IDLE = "#CBD6E2";
export const HDS_BUTTON_COLOR_BORDER_NEUTRAL_DEFAULT_HOVER = "#CBD6E2";
export const HDS_BUTTON_COLOR_BORDER_NEUTRAL_DEFAULT_PRESSED = "#7C98B6";
export const HDS_BUTTON_COLOR_BORDER_NEUTRAL_DARK_IDLE = "#425B76";
export const HDS_BUTTON_COLOR_BORDER_NEUTRAL_DARK_HOVER = "#516F90";
export const HDS_BUTTON_COLOR_BORDER_NEUTRAL_DARK_PRESSED = "#33475b";
export const HDS_BUTTON_COLOR_BORDER_NEUTRAL_LIGHT_IDLE = "#CBD6E2";
export const HDS_BUTTON_COLOR_BORDER_NEUTRAL_LIGHT_HOVER = "#CBD6E2";
export const HDS_BUTTON_COLOR_BORDER_NEUTRAL_LIGHT_PRESSED = "#7C98B6";
export const HDS_BUTTON_COLOR_BORDER_SECONDARY_IDLE = "#ff7a59";
export const HDS_BUTTON_COLOR_BORDER_SECONDARY_HOVER = "#ff7a59";
export const HDS_BUTTON_COLOR_BORDER_SECONDARY_PRESSED = "#ff7a59";
export const HDS_BUTTON_COLOR_BORDER_DANGER_IDLE = "#F2545B";
export const HDS_BUTTON_COLOR_BORDER_DANGER_HOVER = "#ff5962";
export const HDS_BUTTON_COLOR_BORDER_DANGER_PRESSED = "#D94C53";
export const HDS_BUTTON_COLOR_BORDER_TRANSPARENT = "rgba(0, 0, 0, 0)";
export const HDS_BUTTON_COLOR_BORDER_DISABLED = "#EAF0F6";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_PRIMARY_DEFAULT = "#ffffff";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_NEUTRAL_DEFAULT = "#506e91";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_NEUTRAL_DARK = "#ffffff";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_NEUTRAL_LIGHT = "#506e91";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_SECONDARY_DEFAULT = "#ff7a59";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_DANGER_DEFAULT = "#ffffff";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_TRANSPARENT_IDLE = "#0091ae";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_TRANSPARENT_HOVER = "#007a8c";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_TRANSPARENT_PRESSED = "#7fd1de";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_WHITE_DEFAULT = "#33475b";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_GHOST_DEFAULT = "#ffffff";
export const HDS_BUTTON_COLOR_TEXT_ON_BACKGROUND_DISABLED = "#b0c1d4";
export const HDS_BUTTON_COLOR_ICON_SECONDARY_DEFAULT = "#ff7a59";
export const HDS_BUTTON_COLOR_ICON_DANGER_DEFAULT = "#ffffff";
export const HDS_BUTTON_COLOR_ICON_TRANSPARENT_DEFAULT = "#7C98B6";
export const HDS_BUTTON_COLOR_ICON_WHITE_DEFAULT = "#33475b";
export const HDS_BUTTON_COLOR_ICON_GHOST_DEFAULT = "#ffffff";
export const HDS_BUTTON_COLOR_ICON_PRIMARY_DEFAULT = "#ffffff";
export const HDS_BUTTON_COLOR_ICON_NEUTRAL_DEFAULT = "#506e91";
export const HDS_BUTTON_COLOR_ICON_NEUTRAL_DARK = "#ffffff";
export const HDS_BUTTON_COLOR_ICON_NEUTRAL_LIGHT = "#506e91";
export const HDS_BUTTON_COLOR_ICON_DISABLED = "#b0c1d4";
export const HDS_BUTTON_TYPOGRAPHY_DEFAULT = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "500",
  "lineHeight": "16px",
  "fontSize": "14px"
};
export const HDS_BUTTON_TYPOGRAPHY_SMALL = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "lineHeight": "14px",
  "fontSize": "12px"
};
export const HDS_BUTTON_TYPOGRAPHY_EXTRA_SMALL = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "lineHeight": "14px",
  "fontSize": "12px"
};
export const HDS_BUTTON_TYPOGRAPHY_TRANSPARENT_DEFAULT = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "lineHeight": "16px",
  "fontSize": "14px",
  "fontWeight": "600"
};
export const HDS_BUTTON_TYPOGRAPHY_TRANSPARENT_SMALL = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "lineHeight": "14px",
  "fontSize": "12px",
  "fontWeight": "600"
};
export const HDS_BUTTON_TYPOGRAPHY_TRANSPARENT_EXTRA_SMALL = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "lineHeight": "14px",
  "fontSize": "12px",
  "fontWeight": "600"
};
export const HDS_BUTTON_RADIUS_DEFAULT = "3px";
export const HDS_BUTTON_RADIUS_CIRCLE = "50%";
export const HDS_BUTTON_SPACE_PADDING_INLINE_DEFAULT = "24px";
export const HDS_BUTTON_SPACE_PADDING_INLINE_SMALL = "16px";
export const HDS_BUTTON_SPACE_PADDING_INLINE_EXTRA_SMALL = "12px";
export const HDS_BUTTON_SPACE_PADDING_INLINE_TRANSPARENT_DEFAULT = "12px";
export const HDS_BUTTON_SPACE_PADDING_INLINE_TRANSPARENT_SMALL = "8px";
export const HDS_BUTTON_SPACE_PADDING_INLINE_TRANSPARENT_EXTRA_SMALL = "6px";
export const HDS_BUTTON_SPACE_PADDING_BLOCK_DEFAULT = "11px";
export const HDS_BUTTON_SPACE_PADDING_BLOCK_SMALL = "8px";
export const HDS_BUTTON_SPACE_PADDING_BLOCK_EXTRA_SMALL = "5px";
export const HDS_BUTTON_SPACE_GAP_DEFAULT = "7px";
export const HDS_BUTTON_SPACE_GAP_SMALL = "6px";
export const HDS_BUTTON_SPACE_GAP_EXTRA_SMALL = "6px";
export const HDS_BUTTON_WIDTH_BORDER_DEFAULT = "1px";
export const HDS_BUTTON_LINE_HEIGHT_DEFAULT = "16px";
export const HDS_BUTTON_LINE_HEIGHT_SMALL = "14px";
export const HDS_BUTTON_LINE_HEIGHT_EXTRA_SMALL = "14px";
export const HDS_BUTTON_FONT_SIZE_DEFAULT = "14px";
export const HDS_BUTTON_FONT_SIZE_SMALL = "12px";
export const HDS_BUTTON_FONT_SIZE_EXTRA_SMALL = "12px";
export const HDS_BUTTON_SIZE_ICON_DEFAULT = "14px";
export const HDS_BUTTON_SIZE_ICON_SMALL = "12px";
export const HDS_BUTTON_SIZE_ICON_EXTRA_SMALL = "12px";
export const HDS_ICON_BUTTON_WIDTH_DEFAULT = "40px";
export const HDS_ICON_BUTTON_WIDTH_SMALL = "32px";
export const HDS_ICON_BUTTON_WIDTH_EXTRA_SMALL = "26px";
export const HDS_ICON_BUTTON_FONT_SIZE_DEFAULT = "18px";
export const HDS_ICON_BUTTON_FONT_SIZE_SMALL = "16px";
export const HDS_ICON_BUTTON_FONT_SIZE_EXTRA_SMALL = "12px";
export const HDS_CARD_SPACE_PADDING_BLOCK_DEFAULT = "40px";
export const HDS_CARD_SPACE_PADDING_BLOCK_COMPACT = "24px";
export const HDS_CARD_SPACE_PADDING_INLINE_DEFAULT = "40px";
export const HDS_CARD_SPACE_PADDING_INLINE_COMPACT = "24px";
export const HDS_CARD_SHADOW = "0 1px 5px 0 rgba(45, 62, 80, 0.12)";
export const HDS_CARD_TYPOGRAPHY_HEADING_EXTRA_LARGE = {
  "lineHeight": "1.2",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "700",
  "fontSize": "32px"
};
export const HDS_CARD_TYPOGRAPHY_HEADING_LARGE = {
  "lineHeight": "1.2",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "fontSize": "24px"
};
export const HDS_CARD_TYPOGRAPHY_HEADING_MEDIUM = {
  "lineHeight": "1.2",
  "fontSize": "20px",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "600"
};
export const HDS_CARD_TYPOGRAPHY_HEADING_SMALL = {
  "lineHeight": "1.2",
  "fontWeight": "600",
  "fontSize": "16px",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif"
};
export const HDS_CARD_TYPOGRAPHY_HEADING_EXTRA_SMALL = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "lineHeight": "24px",
  "fontSize": "14px"
};
export const HDS_TILE_SPACE_PADDING_BLOCK_DEFAULT = "32px";
export const HDS_TILE_SPACE_PADDING_BLOCK_COMPACT = "20px";
export const HDS_TILE_SPACE_PADDING_INLINE_DEFAULT = "32px";
export const HDS_TILE_SPACE_PADDING_INLINE_COMPACT = "20px";
export const HDS_TILE_SPACE_MARGIN_BLOCK_FLUSH = "0";
export const HDS_TILE_SPACE_MARGIN_BLOCK_EXTRA_SMALL = "8px";
export const HDS_TILE_SPACE_MARGIN_BLOCK_SMALL = "12px";
export const HDS_TILE_SPACE_MARGIN_BLOCK_MEDIUM = "24px";
export const HDS_TILE_SPACE_MARGIN_BLOCK_LARGE = "32px";
export const HDS_TILE_SPACE_MARGIN_BLOCK_EXTRA_LARGE = "64px";
export const HDS_TILE_BORDER_DEFAULT = "1px solid #CBD6E2";
export const HDS_CONTAINER_RADIUS_BORDER = "3px";
export const HDS_CONTAINER_COLOR_BACKGROUND = "#ffffff";
export const HDS_COLUMN_SPACE_PADDING_BLOCK_DEFAULT = "16px";
export const HDS_COLUMN_SPACE_PADDING_BLOCK_NONE = "0";
export const HDS_COLUMN_SPACE_PADDING_INLINE_DEFAULT = "24px";
export const HDS_COLUMN_SPACE_PADDING_INLINE_NONE = "0";
export const HDS_INPUT_COLOR_BACKGROUND_DEFAULT = "#F5F8FA";
export const HDS_INPUT_COLOR_BACKGROUND_DISABLED = "#EAF0F6";
export const HDS_INPUT_COLOR_BACKGROUND_READ_ONLY = "#EAF0F6";
export const HDS_INPUT_COLOR_BACKGROUND_ON_SURFACE = "#ffffff";
export const HDS_INPUT_COLOR_BORDER_IDLE = "#CBD6E2";
export const HDS_INPUT_COLOR_BORDER_DISABLED = "#EAF0F6";
export const HDS_INPUT_COLOR_BORDER_READ_ONLY = "#EAF0F6";
export const HDS_INPUT_COLOR_TEXT_ENTERED = "#33475b";
export const HDS_INPUT_COLOR_TEXT_PLACEHOLDER = "#7c98b6";
export const HDS_INPUT_COLOR_TEXT_DISABLED = "#b0c1d4";
export const HDS_INPUT_SPACE_PADDING_BLOCK_DEFAULT = "9px";
export const HDS_INPUT_SPACE_PADDING_BLOCK_SMALL = "4px";
export const HDS_INPUT_SPACE_PADDING_INLINE_DEFAULT = "10px";
export const HDS_INPUT_SPACE_PADDING_INLINE_SMALL = "10px";
export const HDS_INPUT_SPACE_GAP_INLINE_DEFAULT = "10px";
export const HDS_INPUT_SPACE_GAP_INLINE_SMALL = "10px";
export const HDS_INPUT_RADIUS_BORDER = "3px";
export const HDS_INPUT_HEIGHT_DEFAULT = "40px";
export const HDS_INPUT_HEIGHT_SMALL = "32px";
export const HDS_INPUT_BORDER_DEFAULT = "1px solid #CBD6E2";
export const HDS_INPUT_TYPOGRAPHY_TEXT_DEFAULT = {
  "lineHeight": "22px",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "fontSize": "16px"
};
export const HDS_INPUT_TYPOGRAPHY_TEXT_SMALL = {
  "lineHeight": "22px",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "fontSize": "14px"
};
export const HDS_INPUT_SIZE_ICON_DEFAULT = "16px";
export const HDS_INPUT_SIZE_ICON_SMALL = "14px";
export const HDS_INPUT_LABEL_SPACE_MARGIN_BLOCK_DEFAULT = "4px";
export const HDS_LABEL_TYPOGRAPHY_DEFAULT = {
  "fontWeight": "500",
  "fontSize": "14px",
  "lineHeight": "24px",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif"
};
export const HDS_LABEL_TYPOGRAPHY_SMALL = {
  "fontWeight": "500",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "lineHeight": "24px",
  "fontSize": "12px"
};
export const HDS_INTERACTIVE_UI_COLOR_BACKGROUND_TRANSPARENT = "rgba(255, 255, 255, 0)";
export const HDS_INTERACTIVE_UI_COLOR_BACKGROUND_ACTION = "#e5f5f8";
export const HDS_INTERACTIVE_UI_COLOR_BACKGROUND_NONINTERACTIVE = "#EAF0F6";
export const HDS_INTERACTIVE_UI_COLOR_TEXT_DEFAULT = "#33475b";
export const HDS_INTERACTIVE_UI_COLOR_TEXT_DISABLED = "#b0c1d4";
export const HDS_INTERACTIVE_UI_COLOR_ACTION_IDLE = "#0091ae";
export const HDS_INTERACTIVE_UI_COLOR_ACTION_HOVER = "#007a8c";
export const HDS_INTERACTIVE_UI_COLOR_ACTION_PRESSED = "#7fd1de";
export const HDS_INTERACTIVE_UI_COLOR_ICON_DEFAULT = "#0091ae";
export const HDS_INTERACTIVE_UI_COLOR_ICON_EXTERNAL = "#7C98B6";
export const HDS_INTERACTIVE_UI_COLOR_ICON_BODY = "#33475b";
export const HDS_INTERACTIVE_UI_COLOR_BORDER_ACTION = "#7fd1de";
export const HDS_SELECTABLE_ITEM_COLOR_BACKGROUND_IDLE = "rgba(255, 255, 255, 0)";
export const HDS_SELECTABLE_ITEM_COLOR_BACKGROUND_HOVER = "#e5f5f8";
export const HDS_SELECTABLE_ITEM_COLOR_BACKGROUND_PRESSED = "#e5f5f8";
export const HDS_SELECTABLE_ITEM_COLOR_BACKGROUND_SELECTED = "#e5f5f8";
export const HDS_SELECTABLE_ITEM_COLOR_BACKGROUND_DISABLED = "rgba(255, 255, 255, 0)";
export const HDS_SELECTABLE_ITEM_COLOR_BACKGROUND_SELECTEDDISABLED = "#e5f5f8";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_BODY_IDLE = "#33475b";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_BODY_HOVER = "#33475b";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_BODY_PRESSED = "#33475b";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_BODY_SELECTED = "#33475b";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_BODY_DISABLED = "#b0c1d4";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_BODY_SELECTEDDISABLED = "#b0c1d4";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_ACTION_IDLE = "#0091ae";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_ACTION_HOVER = "#007a8c";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_ACTION_PRESSED = "#7fd1de";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_ACTION_SELECTED = "#0091ae";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_ACTION_DISABLED = "#b0c1d4";
export const HDS_SELECTABLE_ITEM_COLOR_TEXT_ACTION_SELECTEDDISABLED = "#b0c1d4";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_ACTION_IDLE = "#0091ae";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_ACTION_HOVER = "#0091ae";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_ACTION_PRESSED = "#0091ae";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_ACTION_SELECTED = "#0091ae";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_ACTION_DISABLED = "#0091ae";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_ACTION_SELECTEDDISABLED = "#0091ae";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_BODY_IDLE = "#33475b";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_BODY_HOVER = "#33475b";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_BODY_PRESSED = "#33475b";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_BODY_SELECTED = "#33475b";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_BODY_DISABLED = "#b0c1d4";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_BODY_SELECTEDDISABLED = "#b0c1d4";
export const HDS_SELECTABLE_ITEM_COLOR_ICON_EXTERNAL_IDLE = "#7C98B6";
export const HDS_SELECTABLE_ITEM_COLOR_INDICATOR_SELECTED = "#00a4bd";
export const HDS_SELECTABLE_ITEM_TYPOGRAPHY_ITEM_DEFAULT = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "lineHeight": "24px",
  "fontSize": "14px"
};
export const HDS_SELECTABLE_ITEM_TYPOGRAPHY_TITLE = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "600",
  "lineHeight": "24px",
  "fontSize": "14px"
};
export const HDS_SELECTABLE_ITEM_SPACE_PADDING_INLINE_DEFAULT = "20px";
export const HDS_SELECTABLE_ITEM_SPACE_PADDING_NESTED = "40px";
export const HDS_SELECTABLE_ITEM_SPACE_PADDING_BLOCK_DEFAULT = "8px";
export const HDS_SELECTABLE_ITEM_SPACE_GAP = "8px";
export const HDS_SELECTABLE_ITEM_SIZE_MIN_HEIGHT = "40px";
export const HDS_SELECTABLE_BOX_COLOR_BACKGROUND_IDLE = "rgba(255, 255, 255, 0)";
export const HDS_SELECTABLE_BOX_COLOR_BACKGROUND_HOVER = "rgba(255, 255, 255, 0)";
export const HDS_SELECTABLE_BOX_COLOR_BACKGROUND_PRESSED = "#e5f5f8";
export const HDS_SELECTABLE_BOX_COLOR_BORDER_IDLE = "#7fd1de";
export const HDS_SELECTABLE_BOX_COLOR_BORDER_HOVER = "#7fd1de";
export const HDS_SELECTABLE_BOX_COLOR_BORDER_PRESSED = "#7fd1de";
export const HDS_DISCLOSURE_DURATION_MAX_HEIGHT_DEFAULT = "200ms";
export const HDS_DISCLOSURE_DURATION_OPACITY_DEFAULT = "200ms";
export const HDS_DISCLOSURE_DURATION_TRANSFORM_DEFAULT = "200ms";
export const HDS_DISCLOSURE_EASING_MAX_HEIGHT_DEFAULT = "cubic-bezier(0.25, 0.1, 0.25, 1)";
export const HDS_DISCLOSURE_EASING_OPACITY_DEFAULT = "cubic-bezier(0.42, 0, 0.58, 1)";
export const HDS_DISCLOSURE_EASING_TRANSFORM_DEFAULT = "cubic-bezier(0.42, 0, 0.58, 1)";
export const HDS_TYPE_SCALE_BODY_DEFAULT = "14px";
export const HDS_TYPE_SCALE_BODY_SMALL = "12px";
export const HDS_TYPE_SCALE_BODY_MEDIUM = "14px";
export const HDS_TYPE_SCALE_BODY_LARGE = "16px";
export const HDS_TYPE_SCALE_DISPLAY_HEADING1 = "32px";
export const HDS_TYPE_SCALE_DISPLAY_HEADING2 = "24px";
export const HDS_TYPE_SCALE_DISPLAY_HEADING3 = "22px";
export const HDS_TYPE_SCALE_DISPLAY_HEADING4 = "20px";
export const HDS_TYPE_SCALE_DISPLAY_HEADING5 = "16px";
export const HDS_TYPE_SCALE_DISPLAY_HEADING6 = "14px";
export const HDS_TYPE_SCALE_CODE_DEFAULT = "14px";
export const HDS_TEXT_BODY = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "lineHeight": "24px",
  "fontSize": "14px"
};
export const HDS_TEXT_BODY_LARGE = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "lineHeight": "24px",
  "fontSize": "16px"
};
export const HDS_TEXT_BODY_SMALL = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "lineHeight": "18px",
  "fontSize": "12px"
};
export const HDS_TEXT_MICROCOPY = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "300",
  "lineHeight": "18px",
  "fontSize": "12px"
};
export const HDS_TEXT_CODE = {
  "fontFamily": "Source Code Pro, Consolas, Monaco, Courier New, monospace",
  "fontWeight": "500",
  "fontSize": "14px",
  "lineHeight": "24px"
};
export const HDS_DISPLAY_HEADING1 = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "700",
  "lineHeight": "1.2",
  "fontSize": "32px"
};
export const HDS_DISPLAY_HEADING2 = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "lineHeight": "1.2",
  "fontSize": "24px",
  "fontWeight": "300"
};
export const HDS_DISPLAY_HEADING3 = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "500",
  "lineHeight": "1.2",
  "fontSize": "22px"
};
export const HDS_DISPLAY_HEADING4 = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "lineHeight": "1.2",
  "fontSize": "20px",
  "fontWeight": "600"
};
export const HDS_DISPLAY_HEADING5 = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "lineHeight": "1.2",
  "fontSize": "16px",
  "fontWeight": "600"
};
export const HDS_DISPLAY_HEADING6 = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "lineHeight": "1.2",
  "fontSize": "14px",
  "fontWeight": "600"
};
export const HDS_DISPLAY_TAGLINE = {
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif",
  "fontWeight": "700",
  "fontSize": "55px",
  "lineHeight": "1.2"
};
export const HDS_DISPLAY_PAGE_HEADING = {
  "fontSize": "20px",
  "lineHeight": "1.2",
  "fontWeight": "600",
  "fontFamily": "Lexend Deca, Helvetica, Arial, sans-serif"
};