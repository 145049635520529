module.exports = {
  "/": {
    "success": {
      "index page loaded": [
        "INDEX_LOAD"
      ]
    }
  },
  "/forms": {
    "success": {
      "index page loaded": [
        "FORMS_LINK_LOADED"
      ]
    }
  },
  "/live-chat": {
    "success": {
      "index page loaded": [
        "LIVE_CHAT_LINK_LOADED"
      ]
    }
  }
};